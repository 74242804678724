import React from 'react';

import * as Styles from './styles';

const Footer: React.FC = () => {
  return (
    <>
      <Styles.Container />
    </>
  );
};

export default Footer;
